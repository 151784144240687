.m-1 {
  margin: 1rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.p-1 {
  padding: 1rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.m-2 {
  margin: 2rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.p-2 {
  padding: 2rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.m-3 {
  margin: 3rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.p-3 {
  padding: 3rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.m-4 {
  margin: 4rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.p-4 {
  padding: 4rem;
}
.pt-4 {
  padding-top: 4rem;
}
.pb-4 {
  padding-bottom: 4rem;
}
.m-5 {
  margin: 5rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.p-5 {
  padding: 5rem;
}
.pt-5 {
  padding-top: 5rem;
}
.pb-5 {
  padding-bottom: 5rem;
}
.m-1 {
  margin: 1rem;
}
.mt-1 {
  margin-top: 1rem;
}
.mb-1 {
  margin-bottom: 1rem;
}
.p-1 {
  padding: 1rem;
}
.pt-1 {
  padding-top: 1rem;
}
.pb-1 {
  padding-bottom: 1rem;
}
.m-2 {
  margin: 2rem;
}
.mt-2 {
  margin-top: 2rem;
}
.mb-2 {
  margin-bottom: 2rem;
}
.p-2 {
  padding: 2rem;
}
.pt-2 {
  padding-top: 2rem;
}
.pb-2 {
  padding-bottom: 2rem;
}
.m-3 {
  margin: 3rem;
}
.mt-3 {
  margin-top: 3rem;
}
.mb-3 {
  margin-bottom: 3rem;
}
.p-3 {
  padding: 3rem;
}
.pt-3 {
  padding-top: 3rem;
}
.pb-3 {
  padding-bottom: 3rem;
}
.m-4 {
  margin: 4rem;
}
.mt-4 {
  margin-top: 4rem;
}
.mb-4 {
  margin-bottom: 4rem;
}
.p-4 {
  padding: 4rem;
}
.pt-4 {
  padding-top: 4rem;
}
.pb-4 {
  padding-bottom: 4rem;
}
.m-5 {
  margin: 5rem;
}
.mt-5 {
  margin-top: 5rem;
}
.mb-5 {
  margin-bottom: 5rem;
}
.p-5 {
  padding: 5rem;
}
.pt-5 {
  padding-top: 5rem;
}
.pb-5 {
  padding-bottom: 5rem;
}
.xx-large {
  font-size: 4rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.large {
  font-size: 3rem;
  line-height: 1.2;
  margin-bottom: 1rem;
}
.lead {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}
.text-primary {
  color: #e45826;
}
.btn {
  display: inline-block;
  background: #1b1a17;
  color: #fff;
  padding: 8px 20px;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  margin-right: 0.5rem;
  outline: none;
  transition: all 0.2s ease-in;
}
.btn.btn-primary:hover {
  background: #e7693d;
}
.btn.btn-delete{
  background: #df5151;
}
.btn.btn-delete:hover{
  background: #de2b2b;
}


.btn:hover {
  background: #524f46;
  color: #fff;
}
.alert-display {
  position: fixed;
  z-index: 1;
  top: 0.5 px;
  width: 100%;
  margin: 0 auto;
}
.alert {
  padding: 0.8rem;
  max-width: fit-content;
  margin: auto;
  margin-top: 10px;
  top: 0;
  opacity: 0.9;
  background: #f4f4f4;
  color: #333;
}
.alert.alert-primary {
  background-color: #1b1a17;
  color: #fff;
}
.alert.alert-danger {
  background-color: #e45826;
  color: #fff;
}
.alert.alert-success {
  background-color: #f0a500;
  color: #fff;
}
.alert.alert-dark {
  background-color: #1b1a17;
  color: #fff;
}
.table {
  margin: 0 auto;
  background-color: #ffffffb4;
  border-radius: 1em;
  border-collapse: collapse;
  overflow: hidden;

}
.table th,
.table td {
  padding: 1rem;
  text-align: left;
}

.table tr:nth-child(even) {
  background-color: #f0f0f0b4;
}

.table tr{
  transition: all 0.3s ease-in;
}

.table tr:hover{
  cursor: pointer;
  background-color: #dad3beb4;
}

.table th {
  background-color: #1b1a17ec;
  color: #fff;
  
  
  
}
.form-group {
  margin: 1.2rem auto;
}
.form-text {
  display: block;
  margin-top: 0.3rem;
  color: #333;
}
.form input[type="text"],
.form input[type="email"],
.form input[type="password"],
.form input[type="date"],
.form input[type="datetime-local"],
.form select,
.form textarea {
  display: block;
  width: 100%;
  padding: 0.7rem;
  font-size: 1.2rem;
  margin: 0 auto;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.508);
}

.form input[type="text"]:focus,
.form input[type="email"]:focus,
.form input[type="password"]:focus,
.form select:focus,
.form textarea:focus {
  outline: #e45826 solid 1px;
  box-shadow: 0 0 20px #f0a500;
}

.form input[type="submit"] {
  font: inherit;
}
@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
    height: 100px !important;
  }
  .navbar h1 {
    margin-bottom: 10px;
  }
  .navbar .logo {
    height: 70% !important;
  }
  .navbar .logo img {
    height: 50%;
  }
  .profiles .profile {
    grid-template-columns: 1fr;
    justify-items: center;
    text-align: center;
  }
  .profiles .profile img {
    width: 100px;
    margin-right: 0px;
  }
  .profiles .profile ul {
    margin-top: 20px;
    display: flex;
  }
  .profiles .profile .btn {
    margin-right: 0;
  }
  .profile-edit {
    display: block;
  }
  .profile-edit .btn {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  .container {
    margin-top: 8rem;
  }
  .x-large {
    font-size: 3rem;
  }
  .large {
    font-size: 2rem;
  }
  .lead {
    font-size: 1rem;
  }
  .posts .post {
    grid-template-columns: 1fr;
  }
  .form input[type="text"],
  .form input[type="email"],
  .form input[type="password"],
  .form input[type="date"],
  .form select,
  .form textarea {
    width: 100%;
  }
}
@media (max-width: 500px) {
  .navbar {
    flex-direction: column;
    height: 100px !important;
  }
  .hide-sm {
    display: none;
  }
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
body {
  font-family: "Raleway", sans-serif;
  font-size: 1rem;
  line-height: 1.6;
  /* background-size: 400% 400%; */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  /* background-image: linear-gradient(180deg, rgba(255,244,224,1) 0%, rgba(255,191,155,1) 100%); */
  /* background-color: #524f46; */
  background-image: url(./img/mesh-578.png);
  color: #333;
}
a {
  text-decoration: none;
  color: #1b1a17;
}
ul {
  list-style: none;
}
img {
  width: 100%;
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  padding: 0.7rem 2rem;
  position: fixed;
  z-index: 1;
  width: 100%;
  height: 80px;
  top: 0;
  border-bottom: solid 1px #f0a500;
  background-color: #1b1a17;
  opacity: 0.9;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.564);
}
.navbar h1 {
  font-size: xx-large;
  font-weight: bolder;
}
.navbar ul {
  display: flex;
  align-items: center;
}
.navbar a {
  color: #fff;
  padding: 0.45rem;
  margin: 0 0.25rem;
}
.navbar a:hover {
  color: #f0a500;
}
.navbar .logo {
  display: flex;
  justify-content: start;
  height: 100%;
}
.navbar .logo img {
  height: 100%;
  width: -moz-fit-content;
  width: fit-content;
  -o-object-fit: contain;
  object-fit: contain;
}
.landing {
  position: relative;
  background-image: url("./img/1-95.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
}
.landing-inner {
  color: #fff;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  width: 80%;
  margin: auto;
  align-items: center;
  justify-content: center;
}
.dark-overlay {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}
.container {
  max-width: 1100px;
  margin: auto;
  overflow: hidden;
  padding: 0 2rem;
  margin-top: 6rem;
  margin-bottom: 3rem;
  text-align: center;
}

.upload__btn {
  display: flex;
  gap: 20px;
}

.upload__img-wrap {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 20px;
}

.upload__img-box {
  position: relative;
  width: auto;
  height: 100px;
}

.upload__img-wrap img {
  height: 100px;
  width: auto;
  margin-right: 10px;
  margin-bottom: 10px;
}

.upload__img-wrap button {
  position: absolute;
  right: 0;
  background-color: #1b1a1747;
}

.card {
  background-color: rgba(255, 255, 255, 0.348);
  padding: 20px 30px;
  border-radius: 20px;
  max-width: 700px;
  margin: 3rem auto;
  backdrop-filter: blur(14px);
}

.panels{
  display:grid;
  grid-template-columns: repeat( auto-fit, minmax(150px, 1fr) );
  gap:20px;
  place-items: center;
  width: 330px;
  margin: 0px auto;
}

.panel{
  background-color: rgba(255, 255, 255, 0.348);
  padding: 20px 30px;
  aspect-ratio: 1/1;
  border-radius: 20px;
  width: 150px;
  backdrop-filter: blur(14px);
  display: flex;
  justify-content: center;
  align-content: center;
  transition: all 0.3s ease-in-out;
}

.panel:hover{
  background-color: rgba(0, 0, 0, 0.548);
  cursor: pointer;
  color: white;
}

.panel p{
  height: fit-content;
  margin: auto 0px;
  font-size: 1.4rem;
  line-height: 50px;
}

.twocolumns{
  display: flex;
}

.subtitle{
  font-size:large;
}

.thumbnail{
  height:300px;
  border-radius: 20px;
  overflow: hidden;
}

.thumbnail img{
  height: 100%;
  object-fit: contain;
}

.heading{
  display: flex;
  justify-content: space-around;
}

.larger{
  max-width: 1300px;
}

.description{
  margin: 20px 0px;
}

.details{
  height: fit-content;
  margin: auto 0;
}

.displayimages{
  display:grid;
  grid-template-columns: repeat( auto-fit, minmax(300px, 1fr) );
  gap:20px;
}